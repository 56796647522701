<template>
  <el-container>
    <el-header class="header">
      <div class="top">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        库存查询
        <span class="right"></span>
      </div>
      <div class="btm">
        <!-- <div class="box" @click="warehouseDrawerShow=true">
          <div class="left">
            全部仓库
          </div>
          <template>
            <div v-if="warehouseName" class="right">{{warehouseName}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div>
        <div class="box" @click="classDrawerShow=true">
          <div class="left">
            全部分类
          </div>
          <template>
            <div v-if="className" class="right">{{className}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div>
        <div class="box" @click="brandDrawerShow=true">
          <div class="left">
            全部品牌
          </div>
          <template>
            <div v-if="brandName" class="right">{{brandName}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div> -->
        <div class="box-ipt">
          <div class="search">
            <el-input placeholder="请输入商品名称" clearable prefix-icon="el-icon-search" v-model="searchVal"
              @keyup.enter.native="searchChange">
            </el-input>
            <el-button type="primary" @click="searchChange">搜索</el-button>
          </div>
        </div>
      </div>
    </el-header>
    <el-container style="background-color:#f9f9f9;">
      <el-container style="width:30%;min-width:300px; height:calc(100vh - 132px);">
        <el-main class="left-main">
          <div class="card">
            <div :class="leftActiveItem===i?'card-item card-item1':'card-item'" v-for="(item,i) in inventoryList" :key="i"
              @click="leftActiveItem=i">
              <div class="name">{{  item.name}}</div>
              <div class="size">规格：{{  item.shopspecs_id}}</div>
              <div class="num">
                <div class="left">批次数：{{  item.putnum}}{{ item.shopunit_id }}</div>
                <div class="left">库存数：{{  item.stock}}{{ item.shopunit_id }}</div>
              </div>
            </div>
          <empty title="暂无库存信息" v-if="inventoryList.length == 0"></empty>
          </div>
        </el-main>
      </el-container>
      <el-container style="width:70%; height:calc(100vh - 132px);">
        <el-main class="right-main">
          <div class="info">
            <div class="r-title">
              库存详情
            </div>
            <div class="info-box" v-if="inventoryList[leftActiveItem]">
              <div class="cell">
                <div class="left">商品名称</div>
                <div class="right">{{ inventoryList[leftActiveItem].name }}</div>
              </div>
              <div class="cell">
                <div class="left">规格</div>
                <div class="right">{{ inventoryList[leftActiveItem].shopspecs_id }}</div>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="r-title">
              批次列表
            </div>
            <div class="list-box" v-if="inventoryList[leftActiveItem]">
              <div class="list-item" v-for="(item,i) in inventoryList[leftActiveItem].ruku" :key="i">
                <div class="time"><span>状态：</span>{{ item.type }}</div>
                <div class="time"><span>批次号码：</span>{{ item.pihao }}</div>
                <div class="time"><span>入库数量：</span>{{ item.num }}{{ inventoryList[leftActiveItem].shopunit_id }}</div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 仓库 -->
    <el-drawer :visible.sync="warehouseDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择仓库</span><span class="el-icon-close"
            @click="warehouseDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-main class="cell-list">
          <el-radio-group v-model="warehouseRadio">
            <el-radio :label="'仓库名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                仓库名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="warehouseDrawerShow=false">重置</div>
            <div class="btn right" @click="warehouseConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
    <!-- 分类 -->
    <el-drawer :visible.sync="classDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择分类</span><span class="el-icon-close"
            @click="classDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-main class="cell-list">
          <el-radio-group v-model="classRadio">
            <el-radio :label="'分类名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                分类名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="classDrawerShow=false">重置</div>
            <div class="btn right" @click="classConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
    <!-- 品牌 -->
    <el-drawer :visible.sync="brandDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择品牌</span><span class="el-icon-close"
            @click="brandDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-header>
          <div class="search">
            <el-input placeholder="请输入商品名称或编号" clearable prefix-icon="el-icon-search" v-model="brandSearchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-radio-group v-model="brandRadio">
            <el-radio :label="'品牌名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                品牌名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="brandDrawerShow=false">重置</div>
            <div class="btn right" @click="brandConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
  </el-container>
</template>

<script>
import empty from '../../module/empty.vue';
  export default {
  components: { empty },
    data() {
      return {
        searchVal: '',
        leftActiveItem: 0,
        warehouseDrawerShow: false,
        warehouseName: '',
        warehouseRadio: '',
        classDrawerShow: false,
        className: '',
        classRadio: '',
        brandDrawerShow: false,
        brandSearchVal: '',
        brandName: '',
        brandRadio: '',
        //信息列表
        inventoryList:[]
      }
    },
    created() {
      this.inventory()
    },
    methods: {
      //库存查询
      inventory(){
        this.$http.post('api/store/stock/shopstock',{
          keyword:this.searchVal
        }).then(res =>{
          this.inventoryList = res.data.data
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 搜索
      searchChange() {
        this.inventory()
        // if (this.searchVal.trim()) {
        //   console.log(this.searchVal.trim());
        // } else {
        //   this.$message.error('搜索内容不能为空')
        // }
      },
      warehouseConfirm() {
        this.warehouseName = this.warehouseRadio
        this.warehouseDrawerShow = false

      },
      classConfirm() {
        this.className = this.classRadio
        this.classDrawerShow = false

      },
      brandConfirm() {
        this.brandName = this.brandRadio
        this.brandDrawerShow = false

      },
      handleClose() {
        this.warehouseDrawerShow = false
        this.classDrawerShow = false
        this.brandDrawerShow = false
      }
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: auto !important;
    text-align: center;
    padding: 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e9e9e9;
      padding: 0 20px;


      .left {
        color: #46a6ff;
        font-size: 24px;
      }

      .right {
        font-size: 14px;
        font-weight: normal;
        color: #409eff;
      }
    }

    .btm {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f9f9f9;
      padding: 20px;

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22%;
        min-width: 150px;
        padding: 10px;
        margin-right: 1%;
        border: 1px solid #f2f2f2;
        background-color: #fff;
        font-size: 14px;
        color: #666;
        font-weight: normal;
        box-sizing: border-box;

        .el-icon-arrow-right {
          color: #999;
        }
      }

      .box-ipt {
        width: 31%;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }
    }
  }

  .left-main,
  .right-main {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    margin: 0 20px;
  }

  .left-main {
    border-right: none;
    margin-right: 0;
    padding: 20px !important;


    .card {

      .card-item {
        border: 1px solid #f2f2f2;
        background-color: #f9f9f9;
        margin-bottom: 15px;
        padding: 10px;

        .name {
          margin-bottom: 10px;
        }

        .size {
          font-size: 12px;
          color: #999;
          margin-bottom: 10px;
        }

        .num {
          display: flex;
          justify-content: space-between;
          color: #999;
          font-size: 12px;
        }
      }

      .card-item1 {
        border: 1px solid #409eff;
        background-color: #ecf5ff;
      }
    }
  }

  .right-main {
    border-left: none;
    margin-left: 0;
    color: #666;

    .r-title {
      font-weight: bold;
      padding: 20px 0;
    }

    .info {
      .info-box {
        background-color: #f9f9f9;
        border: 1px solid #f2f2f2;
        padding: 0 10px;
        border-radius: 5px;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          &:first-child {
            border-bottom: 1px solid #f2f2f2;
          }

          .left {
            color: #999;
          }
        }
      }
    }

    .list {
      .list-box {
        .list-item {
          background-color: #f9f9f9;
          border: 1px solid #f2f2f2;
          padding: 10px;
          font-size: 12px;
          border-radius: 5px;
          margin-bottom: 15px;

          .time {
            line-height: 24px;
          }
        }
      }
    }
  }

  /deep/ .el-drawer {
    min-width: 400px;

    .el-drawer__header {
      padding: 0;
      margin: 0;
    }

    .drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #f2f2f2;

      .center {
        font-size: 16px;
        font-weight: bold;
      }

      .el-icon-close {
        font-size: 20px;
        color: #409eff;
      }
    }

    .el-header {
      padding: 20px;

      .search {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-button--primary {
          height: 40px;
          margin-left: -6px;
          z-index: 1;
          font-size: 12px;
          border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
          color: #fff;
          border-color: #409eff;
          background-color: #409eff;
        }

        /deep/ .el-input__inner {
          height: 40px;
          border: 1px solid #dcdfe6;
          border-right: 0;
          border-radius: 5px 0 0 5px;

          &:focus,
          &:hover {
            border-color: #dcdfe6;
          }
        }
      }
    }

    .cell-list {
      width: 100%;
      padding: 10px 20px 20px !important;

      .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio__input.is-checked+.el-radio__label {
          color: #666;
        }

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 10px 0;
          margin-bottom: 10px;
          margin-right: 0;

          .dialog-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }

    .el-footer {
      .footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;


        .btn {
          width: 40%;
          text-align: center;
          border: 1px solid #409eff;
          border-radius: 50px;
          line-height: 40px;
        }

        .left {
          color: #409eff;
        }

        .right {
          background-color: #409eff;
          color: #fff;
          font-weight: bold;
        }
      }
    }

  }

  .main.el-main {
    padding: 0 !important;
  }

</style>
